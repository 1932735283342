import React, { useState } from "react"
import { useSiteQuery } from "../../hooks/Layout/useSiteQuery"
import { useLocalPath } from "../../hooks/Layout/useLocalPath"
//import { useLocale } from "../../hooks/Layout/useLocale"

import { Link } from "gatsby"
//import Img from "gatsby-image"
import { GlobIcon } from "../../svgs/play"
import * as styles from "./footer.module.scss"
import CookiePopup from "../CookiePopup/CookiePopup" 
import { RichText } from "../RichText/RichText"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Footer = () => {
  const {
    footerContactUsLink,
    footerMenuItems,
    footerCookiePopUpText,
    footerSocialIcons,
    footerLtiLogo,
    footerCopyrightText,
    footerCopyrightDescription,
    footerCompanyInfo
  } = useSiteQuery();

console.log('footerCookiePopUpText', footerCookiePopUpText);
    // const baseUrl = useUrl();
    const copyRight = footerCopyrightDescription.footerCopyrightDescription?.split("\\n ");
    const externalLink = (link) => {
        return link && (link.split('/').includes('https:') || link.split('/').includes('http:'));
    }

    const [privacyOpen, setPrivacyOpen] = useState(false)

    const events = ['scroll', 'mousemove', 'touchstart'];
    const [svgInteraction, setSvgInteraction] = useState(false)
    const initButton = () => {
        setSvgInteraction(true)

        events.forEach(event => {
            window.removeEventListener(event, initButton)
        })
    }
    if (typeof window !== 'undefined') {
        events.forEach(event => {
            window.addEventListener(event, initButton)
        })
    }

    //const locale = useLocale()
    const date = new Date();
    const CURRENT_YEAR = date.getFullYear();

    const LtiLogo = getImage(footerLtiLogo.image[0]);

  return (
    <footer className={`styles.footer_module`}>
            <div className={`${styles.footer_module__top} spacer__01`}>
                <div className={styles.col_1}>
                    <GlobIcon className={null} />
                    {
                        footerContactUsLink[0].slug.includes('http')
                            ? <a href={footerContactUsLink[0].slug}>
                                {footerContactUsLink[0].title}
                            </a>
                            : <Link to={useLocalPath(footerContactUsLink[0].slug)}>
                                {footerContactUsLink[0].title}
                            </Link>
                    }
                    {footerCompanyInfo && 
                    <div className={styles.addressDiv}>
                        <RichText content={footerCompanyInfo}/>
                    </div>}
                    

                </div>
                <div className={styles.col_2}>
                    <ul className={styles.footer__nav}>
                        {footerMenuItems.map((items: any, index: number) => {
                            const { title, slug, pdflink } = items;

                            if (externalLink(slug)) {
                                return (
                                    <li key={index}>
                                        <a href={slug} target="_blank">
                                            {title}
                                        </a>
                                    </li>
                                );
                            } else {
                                const pdfLinkUrl = pdflink?.file?.url;
                                return (
                                    <li key={index}>
                                        {pdfLinkUrl ? (
                                            <a href={pdfLinkUrl} target="_blank">
                                                {title}
                                            </a>
                                        ) : (
                                            slug.startsWith('/') ? (
                                                <Link to={useLocalPath(slug)}>
                                                    {title}
                                                </Link>
                                            ) : (
                                                <Link to={useLocalPath(slug)}>
                                                {title}
                                            </Link>
                                            )
                                        )}
                                    </li>
                                );
                            }
                        })}
                        <li>
                            <p className={styles.privacyPopupLink} onClick={e => { setPrivacyOpen(true) }}>{footerCookiePopUpText}</p>
                            <div className={`${styles.wrapper} ${privacyOpen ? styles.privacyOpen : ''}`} >
                                {privacyOpen ? <CookiePopup setOpen={setPrivacyOpen} /> : null}
                            </div>
                        </li>
                    </ul>

                </div>
                {
                    svgInteraction &&
                    <div className={styles.col_3}>
                        <ul className={`${styles.social__icons} ${`styles.d_flex`}}`}>
                            {footerSocialIcons?.map((items: any, index: number) => {
                                const { title, slug, image } = items
                                if (externalLink(slug)) {
                                  const image = getImage(items.image[0])
                                    return (
                                        <li key={index}>
                                            <a href={`${slug}`}>
                                                {/* <Img
                                                    fluid={image[0].fluid}
                                                    className={title}
                                                    alt={title}
                                                ></Img> */}
                                                <GatsbyImage image={image} alt={title} className={title}/>
                                            </a>
                                        </li>
                                    )
                                } else {
                                  const image = getImage(items.image[0])
                                    return (
                                        <li key={index}>
                                            <Link to={useLocalPath(`/${slug}`)}>
                                                {/* <Img
                                                    fluid={image[0].fluid}
                                                    className={title}
                                                    alt={title}
                                                ></Img> */}
                                                <GatsbyImage image={image} alt={title} className={title}/>
                                            </Link>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    </div>
                }
            </div>
            <div className={styles.footer_module__bottom}>
                <a href={footerLtiLogo.slug} className={styles.image}>
                    {/* <Img
                        fluid={footerLtiLogo.image[0].fluid}
                        className={footerLtiLogo.title}
                        alt={footerLtiLogo.title}
                    ></Img> */}
                    <GatsbyImage image={LtiLogo} alt={footerLtiLogo.title} />
                </a>
                <div>{footerCopyrightText &&
                    <p className={styles.footerSmallDesc}>{`${footerCopyrightText} ${CURRENT_YEAR}`}</p>
                }
                    {copyRight[0] &&
                        <p className={styles.footerSmallDesc}>{copyRight[0]}</p>
                    }

                    {copyRight[1] &&
                        <p className={styles.footerSmallDesc}>{copyRight[1]}</p>
                    }

                </div>


            </div>
        </footer>
    )
}

export default Footer

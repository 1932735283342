// extracted by mini-css-extract-plugin
export var addressDiv = "footer-module--address-div--3ecbd";
export var col_1 = "footer-module--col_1--bfe5d";
export var col_2 = "footer-module--col_2--a505c";
export var col_3 = "footer-module--col_3--e252c";
export var footer = "footer-module--footer--42660";
export var footerSmallDesc = "footer-module--footer-small-desc--a0f75";
export var footer__nav = "footer-module--footer__nav--c5020";
export var footer__social_image = "footer-module--footer__social_image--ed18b";
export var footer__socials = "footer-module--footer__socials--5e565";
export var footer_module__bottom = "footer-module--footer_module__bottom--e4b37";
export var footer_module__top = "footer-module--footer_module__top--04846";
export var image = "footer-module--image--34c25";
export var privacyOpen = "footer-module--privacy-open--f1e90";
export var privacyPopupLink = "footer-module--privacy-popup-link--7b61f";
export var social__icons = "footer-module--social__icons--bcfc7";
export var wrapper = "footer-module--wrapper--743a5";
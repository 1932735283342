import React, {useEffect} from 'react'
import * as styles from './cookie-popup.module.scss'
//import Close from '../../static/close.png'
//import Close from '../../static/close-popup-icon.svg'


const CookiePopup = ({setOpen}) => {
    useEffect(() => {
        // Call UC_UI.restartEmbeddings after component mounts
        const timeoutId = setTimeout(() => {
          try {
            window.UC_UI.restartEmbeddings();
          } catch (e) {
            // Handle any errors
          }
        }, 100);
    
        // Clean up timeout if component unmounts before it fires
        return () => clearTimeout(timeoutId);
      }, []);
    return (
        <>
            <div className={styles.overlay} onClick={e =>setOpen(false)}>&nbsp;</div>
            <div className={styles.container}>
            <div className={styles.close} onClick={e =>setOpen(false)} ></div>
                <div className="uc-embed" uc-styling="true" uc-embedding-type="category" uc-data="all"></div>
            </div>
            <style>
        {`
          .uc-embed-list-headline,
          .uc-embed-list-headline-description,
          .uc-embed-opened-button-content,
          .uc-embed-opened-content,
          .uc-embed-collapsed-button-content {
            color: #000000 !important;
          }
        `}
      </style>
        </>
    )
}

export default CookiePopup

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import React from 'react';
import Header from './Header/header';
import Footer from './Footer/footer';
import ".././style/_global.scss";


const Layout = ({ children }) => {
  return (
    <div>
      <Header/>
           <main className="main">{children}</main>
      <Footer/>
  </div>
  );
};

export default Layout;

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

import React from "react"
import Layout from "./src/components/layout"
import Scripts from "./src/components/Scripts/Scripts"
export const wrapPageElement = ({ element }) => {
  return (
    <>
      <Scripts />
      <Layout>{element}</Layout>
    </>
  )
}
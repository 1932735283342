import React from "react"
import { useLocale } from "../../hooks/Layout/useLocale"
import * as styles from "./Markdown.module.scss"
 
type NodeProps = {
    children: React.ReactNode
}



export const Bold = ({ children }: NodeProps) => <strong>{children}</strong>

export const Superscript = ({ children }: NodeProps) => <a target= "_blank">{children}</a>


export const Text = ({ children }: NodeProps) => {
    const locale = useLocale();

    return (
        <p className={`${styles.para} rich-text-content`}>
            {children}
        </p>
    );
};


export const Heading1 = ({ children }: NodeProps) => (
    <h1 style={{ fontSize: "36px" }}>{children}</h1>
)

export const Heading2 = ({ children }: NodeProps) => (
    <h2 style={{ fontSize: "36px" }}>{children}</h2>
)
export const Heading3 = ({ children }: NodeProps) => (
    <h3 style={{ fontSize: "36px" }}>{children}</h3>
)
export const Heading4 = ({ children }: NodeProps) => (
    <h4 style={{ fontSize: "36px" }}>{children}</h4>
)
export const Heading5 = ({ children }: NodeProps) => (
    <h5 style={{ fontSize: "36px" }}>{children}</h5>
)
export const Heading6 = ({ children }: NodeProps) => (
    <h6 style={{ fontSize: "36px" }}>{children}</h6>
)

import React from "react"
import { useSiteQuery } from "../../hooks/Layout/useSiteQuery"
import * as styles from "./header.module.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

const Header = () => {
  const { liptonLogo, headerMenu } = useSiteQuery()
  const logo = liptonLogo.image[0].gatsbyImageData.images.fallback.src  
  // const logo = getImage(liptonLogo.image[0])

  return (
    <>
      <Helmet><title>Dünyanın Önde Gelen Çay Markası | Lipton</title></Helmet>
      <div className={styles.Navigation}>
        <div className={styles.Navbar}>
          <div className={styles.Nav}>
            <div>
              {/* <GatsbyImage image={logo} alt="Lipton Logo" /> */}
              <img src={logo} alt='Lipton Logo' className={styles.NavLogo} />
            </div>

            {headerMenu.map((element, index) => {
              return (
                <div className={styles.NavItem} key={index}>
                  <a href={element.slug}>{element.title}</a>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default Header

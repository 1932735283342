import React from "react";
import { useUrl } from "./useUrl";

export const useLocalPath = (path) => {
    const url = useUrl()
    if (path === '' || path === null || path === '/') return `/${url}/`

    const checkFirstSlash = (path) => {
        if (path[0] === '/') return path
        else return `/${path}`
    }

    const newPath = checkFirstSlash(path)

    if (newPath[newPath.length - 1] === '/') return `/${url}${newPath}`
    else if (newPath.includes("#")) return `/${url}${newPath}`
    else return `/${url}${newPath}/`
}
module.exports = {
    mapLocale: {
        'en-GB': 'uk/en',
        de: 'de/de',
        'en-EG': 'eg/en',
        'ar-EG': 'eg/ar',
        'en-US': 'us/en',
        'pl': 'pl',
        'ar-AE': 'arabia/ar',
        'en-AE': 'arabia/en',
        'es-PE': 'pe',
        'en-PK': 'pk',
        'sv-SE': 'se',
        'en-CA': 'ca/en',
        'fr-CA': 'ca/fr',
        'nl-NL': 'nl',
        'en-AU': 'au',
        'tr-TR': 'tr',
        'nl-BE': 'be/nl',
        'fr-BE': 'be/fr',
        'fr': 'fr',
        'pl-PL': 'pl',
        'it' : 'it',
        'es-CL': 'cl'
    },
    cookieLang: {
        'en-GB': 'en',
        de: 'de',
        'en-EG': 'en',
        'ar-EG': 'ar',
        'en-US': 'en',
        'pl': 'pl',
        'ar-AE': 'ar',
        'en-AE': 'en',
        'es-PE': 'es',
        'en-PK': 'en',
        'sv-SE': 'se',
        'en-CA': 'en',
        'fr-CA': 'fr',
        'nl-NL': 'nl',
        'es-US': 'es',
        'en-AU': 'au',
        'tr-TR': 'tr',
        'nl-BE': 'nl',
        'fr-BE': 'fr',
        'fr': 'fr',
        'pl-PL': 'pl',
        'it' : 'it',
        'es-CL': 'cl'
    },
    siteUrl: {
        'en-EG': 'eg/en',
        'ar-EG': 'eg/ar',
        'en-AE': 'arabia/en',
        'ar-AE': 'arabia/ar',
        'es-PE': 'pe',
        'en-PK': 'pk',
        'sv-SE': 'se',
        'en-CA': 'ca/en',
        'fr-CA': 'ca/fr',
        'nl-NL': 'nl',
        'en-US': 'us/en',
        'en-AU': 'au',
        'tr-TR': 'tr',
        'nl-BE': 'be/nl',
        'fr-BE': 'be/fr',
        'fr': 'fr',
        'pl-PL': 'pl',
        'it' : 'it',
        'es-CL': 'cl'
    },
    mapLangSelector: {
        'en-GB': 'Great Britain (English)',
        de: 'Germany',
        'en-EG': 'Egypt (English)',
        'ar-EG': 'Egypt (Arabic)',
        'en-US': 'United States (English)',
        'fr-FR': 'France (French)',
        'pl': 'Poland (Polish)',
        'ar-AE': 'UAE (Arabic)',
        'en-AE': 'UAE (English)',
        'es-PE': 'Peru (Spanish)',
        'en-PK': 'Pakistan (English)',
        'sv-SE': 'Sweden (Swedish)',
        'en-CA': 'Canada (English)',
        'fr-CA': 'Canada (French)',
        'nl-NL': 'Netherland (Dutch)',
        'en-AU': 'Australia (English)',
        'tr-TR': 'Turkey (Turkish)',
        'nl-BE': 'Dutch (Belgium)',
        'fr-BE': 'French (Belgium)',
        'fr': 'France(French)',
        'pl-PL': 'Poland (Polish)',
        'it' : 'Italy',
        'es-CL': 'Chile (Spanish)'
    },
    mapAddressCountry: {
        'en-GB': 'United Kingdom',
        de: 'Germany',
        'en-EG': 'Egypt',
        'ar-EG': 'Egypt',
        'en-US': 'United States of America',
        'fr-FR': 'France (French)',
        'pl': 'Poland (Polish)',
        'ar-AE': 'UAE (United Arab Emirates)',
        'en-AE': 'UAE (United Arab Emirates)',
        'es-PE': 'Peru (Spanish)',
        'en-PK': 'Pakistan (English)',
        'sv-SE': 'Sweden (Swedish)',
        'en-CA': 'Canada (English)',
        'fr-CA': 'Canada (French)',
        'nl-NL': 'Netherland (Dutch)',
        'en-AU': 'Australia (English)',
        'tr-TR': 'Turkey (Turkish)',
        'nl-BE': 'Dutch (Belgium)',
        'fr-BE': 'French (Belgium)',
        'fr': 'France (French)',
        'pl-PL': 'Poland (Polish)',
        'it' : 'Italy',
        'es-CL': 'Chile (Spanish)'
    },
    mapFetchifyCode: {
        de: 'DEU',
        'en-EG': 'EGP',
        'ar-EG': 'EGP',
        'en-US': 'USD',
        'fr-FR': 'EUR',
        'pl': 'PLN',
        'ar-AE': 'AED',
        'en-AE': 'AED',
    },
    mapLinks: {
        Wellbeing: {
            'en-GB': 'wellbeing-articles',
            de: 'dein-wohlbefinden',
        },
        Recipes: {
            'en-GB': 'recipes',
            de: 'rezepte',
        },
        News: {
            'en-GB': 'news',
            de: 'news',
        },
    },
    userCentric: {
        'en-EG': 'QasplMwig',
        'ar-EG': 'QasplMwig',
        'en-AE': 'F2srXR71P',
        'ar-AE': 'F2srXR71P',
        'es-PE': 'EzIQdpzlb',
        'en-PK': 'b5swL-w_2',
        'sv-SE': 'S-S9NAt8W',
        'en-CA': 'mDcgfMin6',
        'fr-CA': 'mDcgfMin6',
        'en-US': '_Yffw1vwJ',
        'nl-NL': 'O5dfijQ4-',
        'en-AU': 'gHtAr2NHd',
        'tr-TR': 'ghKEZd6-1',
        'nl-BE': 'eP227--V2',
        'fr-BE': 'eP227--V2',
        'fr': '2VZH6LrunT7dxA',
        'pl-PL': 'tHoXddGW9hlhsN',
        'it' : 'WD5klmpB6OCQBN',
        'es-CL':'bM2dmcUUmRFwfi'
    },
    zendeskCookieLang: {
        'en-GB': 'en',
        'de': 'de',
        'en-EG': 'en',
        'ar-EG': 'ar',
        'en-US': 'en',
        'fr-FR': 'fr',
        'es-US': 'es',
        'en-AU': 'en',
        'en-CA': 'en',
        'fr-CA': 'fr',
        'tr-TR': 'tr',
        'ja-JP': 'ja',
        'ur-PK': 'ur',
        'en-PK': 'en',
        'en-AE': 'en',
        'ar-AE': 'ar',
        'sv-SE': 'sv',
        'nl-BE': 'nl',
        'fr-BE': 'fr',
        'nl-NL': 'nl',
        'fr'   : 'fr',
        'pl-PL': 'pl',
        'it' : 'it',
        'es-CL': 'es'
    },
    zendeskChatDept: {
        'en-US': "United States of America",
        'en-AU': "Australia",
        'en-GB': "United Kingdom",
    },
    zendeskGoLive: {
        'en-US': 'United States of America',
        'tr-TR': 'Turkey (Turkish)',
        'en-PK': 'Pakistan (English)',
        'nl-NL': 'Netherland (Dutch)',
        'en-CA': 'Canada (English)',
        'fr-CA': 'Canada (French)',
        'nl-BE': 'Dutch (Belgium)',
        'fr-BE': 'French (Belgium)',
        'en-AU': 'Australia (English)',
        'en-EG': 'Egypt',
        'ar-EG': 'Egypt',
        'pl': 'Poland (Polish)',
        'sv-SE': 'Sweden (Swedish)',
        'ar-AE': 'UAE (Arabic)',
        'en-AE': 'UAE (English)',
        'fr': 'France(French)',
        'it' : 'Italy',
        'pl-PL': 'Poland (Polish)',
        'es-CL': 'Chile (Spanish)'
    }
}
import { graphql, useStaticQuery } from "gatsby"

export const useSiteQuery = () => {
  const {
    allContentfulGlobalSiteLiptonCayuretim: { edges },
  } = useStaticQuery(graphql`
    query SiteQuery {
      allContentfulGlobalSiteLiptonCayuretim {
        edges {
          node {
            liptonLogo {
              image {
                title
                gatsbyImageData
              }
            }
            headerMenu {
              title
              slug
            }
            footerContactUsLink {
              title
              slug
            }
            footerCompanyInfo {
              raw
            }
            footerMenuItems {
              title
              slug
            }
            footerCookiePopUpText
            footerSocialIcons {
              title
              slug
              image {
                gatsbyImageData
              }
            }
            footerLtiLogo {
              title
              slug
              image {
                gatsbyImageData
              }
            }
            footerCopyrightText
            footerCopyrightDescription {
              footerCopyrightDescription
            }
          }
        }
      }
    }
  `)

  //   return data.allContentfulGlobalSiteLiptonCayuretim.nodes;
  const nodes = edges.map(edge => edge.node);
  return nodes[12];
}

import React, { useEffect, useState, useRef } from "react"
import { Helmet } from "react-helmet"
import { useLocale } from "../../hooks/Layout/useLocale"
import _ from "lodash"
import { cookieLang, userCentric } from "../../utils/mapping"
import { useLocation } from "@reach/router"


const Scripts = () => {
    const location = useLocation()


    const events = [
        "keypress",
        "mousemove",
        "touchstart",
        "touchmove",
        "click",
        "scroll",
    ]
    const locale = useLocale()
    const language = 'tr'
    const settingId = 'rwh5n-v67IBYht'
    
    const cookieScriptInit = () => {
        const script = document.createElement("script")
        script.setAttribute("id", "usercentrics-cmp")
        script.setAttribute(
            "src",
            "https://app.usercentrics.eu/browser-ui/latest/loader.js"
        )
        script.setAttribute("data-settings-id", settingId)
        script.setAttribute("data-language", language)
        script.setAttribute("async", "true")
        document.head.appendChild(script)
        
    }



    const handleEvent = () => {

        cookieScriptInit()
        // if (!document.getElementById("script-gtag")) gtag()
        // if (!document.getElementById("script-gtm")) gtm()
        // if (locale === "tr-TR" && !document.getElementById("script-meta-pixel")) {
        //     facebookMetaPixel()
        // }
        events.forEach(event => window.removeEventListener(event, handleEvent))
    }

    useEffect(() => {
        events.forEach(event => window.addEventListener(event, handleEvent))
    }, [location])

    setTimeout(() => {
        handleEvent()
    }, 3000)

    return <></>
}

export default Scripts
